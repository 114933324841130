import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '../ResponseGenerico';
import { secondAxios } from '@/plugins/axios'


export default class PlanificacionForestalResponse
{
  planificacionForestalId: number=0;
  fechaCreacion: string="";
  usuarioId: string="";
  jefeZona: string="";
  mesDescripcion: string="";
  anio: number=0;
  mes: number=0;
  static async ObtenerPlanificcion(usuarioId:string)
  {
    return Obtener<PlanificacionForestalResponse>(`planificacion/obtenerplanificacion/${usuarioId}`,secondAxios);
  }

  static async ObtenerPlanificacionDetalle(planificacionId:number)
  {
    return Obtener<PlanificacionDetalleRequest>(`planificacion/obtenerplanificacionDetalle/${planificacionId}`,secondAxios);
  }

  static async GuardarPlanificacion(request:PlanificacionCabeceraRequest)
  {
    return Save<PlanificacionCabeceraRequest>('planificacion/agregar',request,secondAxios);
  }

  static async GuardarDetalle(request:PlanificacionDetalleRequest)
  {
    return Save<PlanificacionDetalleRequest>('planificacion/agregarDetalle',request,secondAxios);
  }
}

export class PlanificacionCabeceraRequest
{
  usuarioIdMayordomo!: string;
  usuarioIdJefeZona!: string;
  jefeZona!:string;
  usuarioDescripcion!:string;
  mes!: number;
  mesDescripcion!: string;
  anio!: number;

}


export class PlanificacionDetalleRequest {
  planificacionId: number=0;
  loteId: number=0;
  loteDescripcion: string="";
  ordenInterna: string="";
  areaPlanificada: number=0;
  areaDisponible: number=0;
  cicloId: number=0;
  haciendaId: number=0;
  haciendaDescripcion: string="";
  laborId: number=0;
  laborDescripcion: string="";
  proveedorId: number=0;
  proveedorDescripcion: string="";
  tipoLaborId:number =0;
  cuadrillaId:number = 0;
  cuadrillaName:string = ""
  precioUnitario:number = 0;
}
